import React from "react";
import Amazon from "../images/svg/amazon.svg";
import Walmart from "../images/svg/walmart.svg";
import Ebay from "../images/svg/ebay.svg";
import Fiverr from "../images/svg/fiverr.svg";
import Shopify from "../images/svg/shopify.svg";


const HireMe = () => {
  return (
    <div id="hire" className="hire__me-wrapper py-5">
      <div className="container">
        <div className="text-center">
          <h1>Visit Store & Shop Now</h1>
        </div>
        <div className="row freelance-profiles">
          <a className="upwork"  href=" https://sellercentral.amazon.com/home" target="_blank">
            <a href=" https://sellercentral.amazon.com/home" target="_blank">
              <img src={Amazon} alt="" />
            </a>
          </a>
          <a className="upwork" href="https://www.etsy.com/your/account?ref=seller-platform-mcnav" target="_blank">
            <a href="https://www.etsy.com/your/account?ref=seller-platform-mcnav" target="_blank">
              <img src={Walmart} alt="" />
            </a>
          </a>
          <a className="upwork" href="https://www.ebay.com/usr/saqbi-43" target="_blank">
            <a href="https://www.ebay.com/usr/saqbi-43" target="_blank">
              <img src={Ebay} alt="" />
            </a>
          </a>
          <a className="fiverr" href="https://www.fiverr.com/saqlainbilal112?up_rollout=true" target="_blank">
            <a href="https://www.fiverr.com/saqlainbilal112?up_rollout=true" target="_blank">
              <img src={Fiverr} alt="" />
            </a>
          </a>
          <a className="upwork" href="" >
            <a href="" >
              <img src={Shopify} alt="Service is not avilable" />
            </a>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HireMe;
