import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faShopify,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <div id="services" className="services">
      <h1 className="pt-5">My Services</h1>
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="box">
                <div className="circle">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faShopify}
                    size="2x"
                  />
                </div>
                <h3>Amazon Store</h3>
                <p>
                  scale + Hot offfer +<br /> flat off + top seller + delivery on time
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="box">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faReact} size="2x" />
                </div>
                <h3>Walmart Store</h3>
                <p>
                scale + Hot offfer +<br /> flat off + top seller + delivery on time
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="box">
                <div className="circle">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faBullhorn}
                    size="2x"
                  />
                </div>
                <h3>Ebay Store</h3>
                scale + Hot offfer +<br /> flat off + top seller + delivery on time
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="box">
                <div className="circle">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faFacebookF}
                    size="2x"
                  />
                </div>
                <h3>Shopify Store</h3>
                <p>scale + Hot offfer +<br /> flat off + top seller + delivery on time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
