import React from "react";
import author from "../me.png";
import { Link } from "react-scroll";

const AboutMe = () => {
  return (
    <div id="about" className="container py-5">
      <div className="row">
        <div className="col-lg-6 col-xm-12 about-image-container py-3">
          <figure>
            <img className="author" loading="lazy" src={author} alt="Hammad Ul Hassan, shopify developer, react developer" />
          </figure>
        </div>
        <div className="col-lg-6 col-xm-12 py-3">
          <h1 className="about-me-title">About Me</h1>
          <div className="about-me">
            <p>
            If you are shopping on Amazon, eBay or Walmart, then you are definitely on the Right platform.
            </p>
            <p>
            Help shoppers explore your full range of products with your own branded URL on Amazon.
            </p>
            <p>
           Through this website you can vist all the market place link is avilable blow. You just click on order now button and the you can visit our Amazon , Ebay or walmart account and replace order.We are abilable for you every time and give you good respons. 
            </p>
            <p>
            On the product detail page, select the name of the seller. On the next page, select Ask a Question. Select the appropriate options in the Seller Messaging Assistant chat or fill out the contact form. Your message will be sent to the seller via the Buyer-Seller Messaging Service.
            </p>
            <p>
              Our products is 100% real number one in qulaity. we dont intested to scale copy of any product. 
            <span className="nav-item">
              <Link
                smooth={true}
                to="contact"
                offset={-84}
                href="#"
              >
                &nbsp;discuss
              </Link>
            </span> your product.
            </p>
            <p>Cheers!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
