import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <div id="header" className="header-wraper">
      <div className="main-info">
        <h1>Amazon , Walmart & Ebay Store</h1>
        <Typed
          className="typed-text"
          strings={[
            "Amazon Store",
            "Walmart Store",
            "Ebay Store",
            "Shopify Store",
          
          ]}
          typeSpeed={50}
          backSpeed={60}
          loop
        />
        <div className="header-btns">
        <div className="hire_btn_blink--container ">
          <Link smooth={true} to="hire" href="#" offset={-84} className="btn-main-offer hire_btn_blink">
            Contact me
          </Link>
          </div>
          <Link smooth={true} to="contact" href="#" offset={-84} className="btn-main-offer">
            discuss your Product
          </Link>
        </div>
      </div>
      <div className="header-overlay"></div>
    </div>
  );
};

export default Header;
